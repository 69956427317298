import * as React from 'react';
import SEO from '../components/shared/SEO';
import Container from '../components/shared/Container';

const Credits = () => (
  <Container>
    <SEO title="Kuma Learn - Attributions for images" description="Kuma Learn gives credit where credit is due! Below, I list photographs used for this website." />
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
          <svg className="absolute top-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
            <defs>
              <pattern id="74b3fd99-0a6f-4271-bef2-e80eeafdf357" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width="404" height="384" fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
          </svg>
          <svg className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
            <defs>
              <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width="404" height="384" fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
          </svg>
          <svg className="absolute bottom-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
            <defs>
              <pattern id="d3eb07ae-5182-43e6-857d-35c643af9034" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width="404" height="384" fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">Attributions</span>
          </h1>
          <p className="mt-8 text-xl text-gray-500 leading-8">Kuma Learn gives credit where credit is due! Below, I list photographs used for this website.</p>
        </div>
        <div className="mt-6 prose prose-green prose-lg text-gray-500 mx-auto">
          <h2>Front page</h2>
          <h4>Cover</h4>
          <p>Photo by <a target="_blank" rel="noreferrer nofollow" href="https://www.pexels.com/@pierre-blache-651604">Pierre Blaché</a> on <a target="_blank" rel="noreferrer nofollow" href="https://pexels.com">Pexels</a>.</p>
          <h4>Cards</h4>
          <p>Photo by <a target="_blank" rel="noreferrer nofollow" href="https://search.creativecommons.org/photos/6a06e1b6-fba0-42c5-a595-de592df7b600">Norisa1</a> on Flickr under <a target="_blank" rel="noreferrer nofollow" href="https://creativecommons.org/licenses/by/2.0">CC BY 2.0</a>.</p>
          <p>Photo by <a target="_blank" rel="noreferrer nofollow" href="https://www.pexels.com/@pixabay">Pixabay</a> on <a target="_blank" rel="noreferrer nofollow" href="https://pexels.com">Pexels</a>.</p>
          <p>Photo by <a target="_blank" rel="noreferrer nofollow" href="https://www.pexels.com/@rasmussvinding">Rasmus Svinding</a> on <a target="_blank" rel="noreferrer nofollow" href="https://pexels.com">Pexels</a>.</p>
          <p>Photo by <a target="_blank" rel="noreferrer nofollow" href="https://www.pexels.com/@drdeden88">Deden Dicky Ramdhani</a> on <a target="_blank" rel="noreferrer nofollow" href="https://pexels.com">Pexels</a>.</p>
          <h4>Tokyo Tower background</h4>
          <p>Photo by <a target="_blank" rel="noreferrer nofollow" href="https://www.pexels.com/@nickkwanhk/">Nick Kwan</a> on <a target="_blank" rel="noreferrer nofollow" href="https://pexels.com">Pexels</a>.</p>
        </div>
      </div>
    </div>
  </Container>
);

export default Credits;
